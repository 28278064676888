<template>
  <div>
    <span class="settings__accesses-title settings-title">{{
      $t("settings.accesses.title")
    }}</span>
    <div class="settings__row">
      <!--      <check-block-->
      <!--        class="settings__accesses-check-block settings__check-block"-->
      <!--        v-model="support.support"-->
      <!--        :title="$t('settings.accesses.support')"-->
      <!--        :on_text="$t('settings.accesses.yes')"-->
      <!--        :off_text="$t('settings.accesses.no')"-->
      <!--      ></check-block>-->
      <check-block
        class="settings__accesses-check-block settings__check-block"
        v-model="support.upload"
        :title="$t('settings.accesses.audio_access')"
        :on_text="$t('settings.accesses.yes')"
        :off_text="$t('settings.accesses.no')"
      ></check-block>
      <div class="settings__accesses-warning-text">
        <p v-html="$t('settings.accesses.warning')"></p>
      </div>
    </div>
    <div class="settings__accesses-btn-row settings__btn-row">
      <loader v-if="info.loader"></loader>
      <custom-btn v-else class="button button_theme_blue" @click="apply">{{
        $t("settings.hub.apply")
      }}</custom-btn>
      <span
        class="text-fields-block__info-text text_color_green"
        :class="{ text_color_red: info.error }"
        >{{ info_text }}</span
      >
    </div>
  </div>
</template>

<script>
import CheckBlock from "@/components/recurringComponents/checkBlock";
import Loader from "@/components/recurringComponents/loader";
import CustomBtn from "@/components/recurringComponents/customBtn";
export default {
  name: "settingsAccesses",
  components: {
    CheckBlock,
    Loader,
    CustomBtn,
  },
  data() {
    return {
      info: {
        error: false,
        type: "",
        loader: false,
      },
      support: {
        upload: false,
        support: false,
      },
    };
  },
  computed: {
    info_text() {
      switch (this.info.type) {
        case "progress":
          return this.$i18n.t("settings.accesses.loader.progress");
        case "failed":
          return this.$i18n.t("settings.accesses.loader.failed");
        case "success":
          return this.$i18n.t("settings.accesses.loader.success");
        default:
          return "";
      }
    },
  },
  methods: {
    apply() {
      this.info.loader = true;
      this.$store
        .dispatch("apply_access_params", { support: this.support })
        .then(() => {
          this.info.type = "success";
        })
        .catch(() => {
          this.info.type = "failed";
          this.info.error = true;
        })
        .finally(() => {
          this.info.loader = false;
          setTimeout(() => {
            this.info.type = "";
            this.info.error = false;
          }, [3000]);
        });
    },
  },
};
</script>

<style scoped>
.settings__accesses-title {
  display: inline-block;
  margin-bottom: 32px;
}
.settings__accesses-btn-row {
  margin-top: 32px;
}
.settings__accesses-warning-text {
  min-width: 344px;
  margin-right: 80px;
}

.settings__accesses-warning-text > p {
  max-width: 400px;
  color: #575858;
  margin-top: 0;
  margin-bottom: 0;
}

.settings__accesses-check-block {
  max-width: 350px;
}
</style>
