import { render, staticRenderFns } from "./settingAmazon.vue?vue&type=template&id=71ff241e&scoped=true&"
import script from "./settingAmazon.vue?vue&type=script&lang=js&"
export * from "./settingAmazon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71ff241e",
  null
  
)

export default component.exports