<template>
  <div>
    <span class="settings__hub-title settings-title">{{
      $t("settings.hub.title")
    }}</span>
    <div class="settings__row">
      <div>
        <check-block
          class="settings__check-block"
          v-model="recording_mode"
          :title="$t('settings.hub.settings.recording_mode')"
          :on_text="$t('settings.hub.settings.continuous_recording')"
          :off_text="$t('settings.hub.settings.with_button_recording')"
          :error="record_info.error"
          :error_text="record_info.text"
        ></check-block>
        <check-block-with-time
          ref="time"
          class="settings__check-block"
          v-model="upload_mode"
          :title="$t('settings.hub.settings.upload_mode')"
          :on_text="$t('settings.hub.settings.immediate_upload')"
          :off_text="$t('settings.hub.settings.postponed_upload')"
          :hint="true"
          :hint_text="$t('settings.hub.settings.hints.upload')"
          :error="upload_info.error"
          :error_text="upload_info.text"
          :time="upload_time"
          @update_time="update_time"
        ></check-block-with-time
        ><check-block
          class="settings__check-block"
          v-model="motion_detection"
          :title="$t('settings.hub.settings.motion_detection')"
          :on_text="$t('settings.hub.settings.on')"
          :off_text="$t('settings.hub.settings.off')"
          :hint="true"
          :hint_text="$t('settings.hub.settings.hints.motion')"
          :error="motion_info.error"
          :error_text="motion_info.text"
        ></check-block>
      </div>
    </div>
    <battery-level
      ref="battery"
      class="settings__battery"
      v-model="battery_level"
      :battery_info="battery_info"
    ></battery-level>

    <div class="settings__btn-row">
      <loader v-if="info.loader"></loader>
      <custom-btn v-else class="button button_theme_blue" @click="apply">{{
        $t("settings.hub.apply")
      }}</custom-btn>
      <span
        class="text-fields-block__info-text text_color_green"
        :class="{ text_color_red: info.error }"
        >{{ info_text }}</span
      >
    </div>
  </div>
</template>

<script>
import CheckBlock from "@/components/recurringComponents/checkBlock";
import CheckBlockWithTime from "@/components/recurringComponents/checkBlockWithTime";
import BatteryLevel from "@/components/recurringComponents/batteryLevel";
import CustomBtn from "@/components/recurringComponents/customBtn";
import Loader from "@/components/recurringComponents/loader";

export default {
  name: "settingsHub",
  components: {
    Loader,
    CustomBtn,
    BatteryLevel,
    CheckBlockWithTime,
    CheckBlock,
  },
  data() {
    return {
      info: {
        error: false,
        type: "",
        loader: false,
      },
      recording_mode: true,
      upload_mode: true,
      upload_time: "",
      motion_detection: true,
      battery_level: 30,
      battery_info: {
        error: true,
        error_text: "error",
      },
      record_info: {
        text: "",
        error: false,
      },
      upload_info: {
        text: "",
        error: false,
      },
      motion_info: {
        text: "",
        error: false,
      },
    };
  },
  computed: {
    info_text() {
      switch (this.info.type) {
        case "no_format":
          return this.$i18n.t("errors.format");
        case "no_value":
          return this.$i18n.t("errors.value");
        case "progress":
          return this.$i18n.t("settings.hub.loader.progress");
        case "failed":
          return this.$i18n.t("settings.hub.loader.failed");
        case "success":
          return this.$i18n.t("settings.hub.loader.success");
        default:
          return "";
      }
    },
  },
  methods: {
    validate() {
      this.info.error = false;
      this.info.type = "";

      for (let key in this.$refs) {
        let valid = this.$refs[key].validate();
        if (!valid.valid) {
          this.info.error = true;
          this.info.type = valid.error;
        }
      }
      return !this.info.error;
    },
    update_time(value) {
      this.upload_time = value;
    },
    apply() {
      if (this.validate()) {
        this.info.loader = true;
        this.info.type = "progress";

        let recording = "continuous",
          upload_mode = "instant";

        let params = {
          recording: recording,
          upload_mode: upload_mode,
          upload_time: this.upload_time,
          motion: this.motion_detection,
          ready_to_use_battery: this.battery_level,
        };

        if (!this.recording_mode) {
          recording = "button";
          params["recording"] = recording;
        }
        if (!this.upload_mode) {
          params["upload_mode"] = "scheduled";
          params["upload_time"] = this.upload_time;
        }

        this.$store
          .dispatch("apply_hub_params", params)
          .then(() => {
            this.info.type = "success";
          })
          .catch(() => {
            this.info.type = "failed";
          })
          .finally(() => {
            this.info.loader = false;
          });
      }
    },
  },
};
</script>

<style scoped></style>
