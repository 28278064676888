<template>
  <div>
    <div class="settings-mode__row">
      <custom-text-field
        v-model="key"
        :placeholder="$t('settings.upload.key')"
        class="settings-mode__field"
        @change="refresh"
      ></custom-text-field>
    </div>
    <div class="settings-mode__row">
      <custom-text-field
        v-model="secret_key"
        :placeholder="$t('settings.upload.secret_key')"
        class="settings-mode__field"
        @change="refresh"
      ></custom-text-field>
    </div>
    <div class="settings-mode__row">
      <custom-text-field
        v-model="bucket_name"
        :placeholder="$t('settings.upload.bucket_name')"
        class="settings-mode__field"
        @change="refresh"
      ></custom-text-field>
    </div>
    <div class="settings-mode__row">
      <custom-text-field
        v-model="region_name"
        :placeholder="$t('settings.upload.region')"
        class="settings-mode__field"
        @change="refresh"
      ></custom-text-field>
    </div>
  </div>
</template>

<script>
import CustomTextField from "@/components/recurringComponents/customTextField";

export default {
  name: "settingAmazon",
  props: ["value"],
  components: {
    CustomTextField,
  },
  data() {
    return {
      key: this.value.access_key,
      secret_key: this.value.secret_key,
      bucket_name: this.value.bucket_name,
      region_name: this.value.region_name,
    };
  },
  methods: {
    refresh() {
      this.$emit("input", {
        access_key: this.key,
        secret_key: this.secret_key,
        bucket_name: this.bucket_name,
        region_name: this.region_name,
      });
    },
  },
};
</script>

<style scoped></style>
